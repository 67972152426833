var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login flex_c flex_align_c"},[_c('div',{staticClass:"login-main flex_b anime"},[_vm._m(0),_c('div',{staticClass:"r"},[_c('h3',[_vm._v("注册")]),_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"邮箱注册","name":"1"}}),_c('el-tab-pane',{attrs:{"label":"手机号注册","name":"2"}})],1),_c('el-form',{ref:"loginForm",staticClass:"login-form",attrs:{"model":_vm.form}},[_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 1),expression:"activeName == 1"}],attrs:{"prop":"email","rules":[
            {
              validator: _vm.validatorEmail,
              trigger: 'blur',
            },
          ]}},[_c('el-input',{attrs:{"prefix-icon":"el-icon-message","placeholder":"请输入邮箱"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 2),expression:"activeName == 2"}],attrs:{"prop":"phone","rules":[
            {
              validator: _vm.validatorPhone,
              trigger: 'blur',
            },
          ]}},[_c('el-input',{attrs:{"prefix-icon":"el-icon-mobile-phone","placeholder":"请输入手机号"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('el-form-item',{attrs:{"prop":"validCode","rules":[
            {
              required: true,
              message: '请输入图片验证码',
              trigger: 'blur',
            },
          ]}},[_c('el-row',[_c('el-col',{attrs:{"span":16}},[_c('el-input',{attrs:{"prefix-icon":"el-icon-lock","placeholder":"请输入图片验证码"},model:{value:(_vm.form.validCode),callback:function ($$v) {_vm.$set(_vm.form, "validCode", $$v)},expression:"form.validCode"}})],1),_c('el-col',{attrs:{"span":7,"offset":1}},[_c('img',{attrs:{"src":this.validCode,"alt":""},on:{"click":_vm.getValidCode}})])],1)],1),_c('el-form-item',{attrs:{"prop":"code","rules":[
            {
              required: true,
              message: '请输入短信验证码',
              trigger: 'blur',
            },
          ]}},[_c('el-row',[_c('el-col',{attrs:{"span":16}},[_c('el-input',{attrs:{"prefix-icon":"el-icon-lock","placeholder":"请输入短信验证码"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1),_c('el-col',{attrs:{"span":7,"offset":1}},[_c('el-button',{attrs:{"type":"primary","loading":_vm.codeLoad,"disabled":_vm.codeText != '发送验证码' && _vm.codeText != '重新发送验证码'},on:{"click":_vm.getCode}},[_vm._v(_vm._s(_vm.codeText))])],1)],1)],1),_c('el-form-item',{attrs:{"prop":"password","rules":[
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ]}},[_c('el-input',{attrs:{"prefix-icon":"el-icon-lock","show-password":"","placeholder":"请输入密码"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('el-form-item',{attrs:{"prop":"password","rules":[
            {
              required: true,
              message: '请确认密码',
              trigger: 'blur',
            },
          ]}},[_c('el-input',{attrs:{"prefix-icon":"el-icon-lock","show-password":"","placeholder":"请确认密码"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('el-form-item',[_c('el-button',{staticClass:"block",attrs:{"loading":_vm.loginLoading,"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("登录")])],1),_c('div',{staticClass:"flex_b"},[_c('div'),_c('p',{staticClass:"link"},[_c('a',{attrs:{"href":""}},[_vm._v("已有账号？登录")])])])],1),_vm._m(1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l flex_c flex_align_c"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/public/logo.png"),"alt":""}}),_c('h3',[_vm._v("智能制造 工业共享")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"third"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("第三方注册")])]),_c('div',{staticClass:"list flex_c"},[_c('div',{staticClass:"item"},[_c('i',{staticClass:"iconfont icon-weixin"}),_c('p',[_vm._v("微信注册")])])])])
}]

export { render, staticRenderFns }